import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import AlbumTimeline from './AlbumTimeline';
import DatePicker from './DatePicker';

import { TIMELINE_STATE } from '../../constants';

import block1Url from '../../assets/images/timeline_block_1.svg';
import block2Url from '../../assets/images/timeline_block_2.svg';

type StyledContainerProps = {
  $isAlbum: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>`
  position: relative;
  height: calc(100vh - 50px);
  margin-bottom: 50px;
  overflow: auto;

  ${props =>
    props.$isAlbum &&
    css`
      display: grid;
      grid-template-rows: 1fr auto;

      ${StyledTypeButtons} {
        align-self: end;
      }
    `}

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 100vh;
    margin: 0;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

type StyledTypeButtonProps = {
  $isAlbum: boolean;
};

const StyledTypeButtons = styled.div<StyledTypeButtonProps>`
  text-align: center;
  padding-bottom: 1rem;

  ${props =>
    !props.$isAlbum &&
    css`
      padding-top: 2rem;

      @media (max-width: ${props => props.theme.breakMedium}) {
        padding-top: calc(50px + 2rem);
      }
    `}
`;

type StyledButtonBorderProps = {
  borderUrl: string;
};

const StyledButtonBorder = styled.div<StyledButtonBorderProps>`
  display: inline-block;
  margin: 0 1.5rem;
  ${props => props.theme.borderMask(props.borderUrl)};
`;

type StyledButtonProps = {
  active: boolean;
  maskUrl: string;
};

const StyledButton = styled.input<StyledButtonProps>`
  padding: 0.5rem;
  border: none;
  font-size: 0.75rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(props.maskUrl)};
  ${props =>
    props.active
      ? css`
          background: ${props => props.theme.black};
          color: ${props => props.theme.white};
        `
      : css`
          background: ${props => props.theme.white};
          color: ${props => props.theme.black};
        `};
`;

interface MainTimelineProps {
  albums: {
    edges: {
      node: {
        childMdx: {
          id: string;
          frontmatter: Collections.Album;
          slug: string;
        };
      };
    }[];
  };
  datesShowsObj: Common.DatesShowsObj;
}

const MainTimeline: React.FC<MainTimelineProps> = props => {
  const { albums, datesShowsObj } = props;

  const [state, setState] = useState(TIMELINE_STATE.ALBUM);

  const renderTimeline = () => {
    switch (state) {
      case TIMELINE_STATE.DATE_SELECT:
        return <DatePicker datesShowsObj={datesShowsObj} />;
      case TIMELINE_STATE.ALBUM:
      default:
        return <AlbumTimeline albums={albums} />;
    }
  };

  return (
    <StyledContainer $isAlbum={state == TIMELINE_STATE.ALBUM}>
      <StyledTypeButtons $isAlbum={state == TIMELINE_STATE.ALBUM}>
        <StyledButtonBorder borderUrl={block1Url}>
          <StyledButton
            type="button"
            value="Explore by Album"
            active={state == TIMELINE_STATE.ALBUM}
            maskUrl={block1Url}
            onClick={() => setState(TIMELINE_STATE.ALBUM)}
          />
        </StyledButtonBorder>
        <StyledButtonBorder borderUrl={block2Url}>
          <StyledButton
            type="button"
            value="Explore by Date"
            active={state == TIMELINE_STATE.DATE_SELECT}
            maskUrl={block2Url}
            onClick={() => setState(TIMELINE_STATE.DATE_SELECT)}
          />
        </StyledButtonBorder>
      </StyledTypeButtons>
      {renderTimeline()}
    </StyledContainer>
  );
};

export default MainTimeline;
