import React from 'react';
import styled, { css } from 'styled-components';

type StyledBorderProps = {
  borderUrl: string;
  disabled?: boolean;
};

const StyledBorder = styled.div<StyledBorderProps>`
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  ${props => props.theme.borderMask(props.borderUrl)};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.25;
    `};
`;

type StyledDropdownProps = {
  borderUrl: string;
};

const StyledDropdown = styled.select<StyledDropdownProps>`
  padding: 0.5rem 2rem 0.3rem 0.5rem;
  background: ${props => props.theme.white};
  border: none;
  cursor: pointer;
  font-size: 0.9375rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(props.borderUrl)};

  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;

  &:disabled {
    opacity: 1;
    cursor: default;
  }
`;

const StyledDropdownArrow = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 0.6rem 0.5rem 0.5rem;
  pointer-events: none;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  font-size: 0.9375rem;
  ${props => props.theme.customTextStyle};
`;

interface DropdownProps {
  borderUrl: string;
  options: string[];
  selected: string;
  setSelected: React.MouseEventHandler<HTMLSelectElement>;
  labelMap?: Record<string, string>;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = props => {
  const {
    borderUrl,
    options,
    selected,
    setSelected,
    labelMap,
    disabled,
  } = props;

  const handleOptionChange = e => {
    setSelected(e);
  };

  return (
    <StyledBorder borderUrl={borderUrl} disabled={disabled}>
      <StyledDropdown
        borderUrl={borderUrl}
        value={selected}
        onChange={handleOptionChange}
        disabled={disabled}
      >
        <option value="">-</option>
        {options.map((option, idx) => (
          <option key={idx} value={option}>
            {labelMap ? labelMap[option] : option}
          </option>
        ))}
      </StyledDropdown>
      <StyledDropdownArrow>v</StyledDropdownArrow>
    </StyledBorder>
  );
};

export default Dropdown;
