import React, { useMemo } from 'react';
import { Router } from '@reach/router';
import moment from 'moment';

import MainTimeline from './MainTimeline';
import DateTimeline from './DateTimeline';
import ShowTimeline from './ShowTimeline';
import { keyBy } from 'lodash';

interface TimelineWrapperProps {
  data: {
    album: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Album;
            slug: string;
          };
        };
      }[];
    };
    liveRecording: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveRecording;
            slug: string;
          };
        };
      }[];
    };
    liveVideo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.LiveVideo;
            slug: string;
          };
        };
      }[];
    };
    photo: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Photo;
            slug: string;
          };
        };
      }[];
    };
    polaroid: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Polaroid;
            slug: string;
          };
        };
      }[];
    };
    poster: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Poster;
            slug: string;
          };
        };
      }[];
    };
    text: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Text;
            slug: string;
          };
        };
      }[];
    };
    show: {
      edges: {
        node: {
          childMdx: {
            id: string;
            frontmatter: Collections.Show;
            slug: string;
          };
        };
      }[];
    };
  };
}

const TimelineWrapper: React.FC<TimelineWrapperProps> = props => {
  const {
    data: {
      album,
      liveRecording,
      liveVideo,
      photo,
      polaroid,
      poster,
      text,
      show,
    },
  } = props;

  const showsKeyed = useMemo(() => {
    const shows = show.edges.map(edge => edge.node.childMdx);
    return keyBy(shows, 'slug');
  }, [show]);

  const datesShowsObj = useMemo(() => {
    const datesShowsObj: Common.DatesShowsObj = {};

    const allMedia = [
      ...liveRecording.edges,
      ...liveVideo.edges,
      ...photo.edges,
      ...polaroid.edges,
      ...poster.edges,
      ...text.edges,
    ];

    // Do one pass to populate empty arrays
    for (const show of Object.values(showsKeyed)) {
      const date = moment(show.frontmatter.date);
      const yearObj = datesShowsObj[date.year()] ?? {};
      const monthObj = yearObj[date.month()] ?? {};
      const dayObj = monthObj[date.date()] ?? {};
      const showsArray = dayObj[show.slug] ?? [];

      dayObj[show.slug] = showsArray;
      monthObj[date.date()] = dayObj;
      yearObj[date.month()] = monthObj;
      datesShowsObj[date.year()] = yearObj;
    }

    for (const media of allMedia) {
      const {
        node: { childMdx },
      } = media;

      if (typeof childMdx.frontmatter.show === 'string') {
        const normalisedSlug = childMdx.frontmatter.show
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '');
        const linkedShow = showsKeyed[normalisedSlug] ?? null;

        if (linkedShow === null) {
          console.warn(`Invalid linked show for ${childMdx.frontmatter.title}`);
          continue;
        }

        childMdx.frontmatter.show = linkedShow.frontmatter;
        childMdx.frontmatter.show.slug = normalisedSlug;
      }

      const date = moment(childMdx.frontmatter.show.date);

      const yearObj = datesShowsObj[date.year()] ?? {};
      const monthObj = yearObj[date.month()] ?? {};
      const dayObj = monthObj[date.date()] ?? {};
      const showsArray = dayObj[childMdx.frontmatter.show.slug] ?? [];
      showsArray.push(childMdx);

      dayObj[childMdx.frontmatter.show.slug] = showsArray;
      monthObj[date.date()] = dayObj;
      yearObj[date.month()] = monthObj;
      datesShowsObj[date.year()] = yearObj;
    }

    return datesShowsObj;
  }, [liveRecording, liveVideo, photo, polaroid, poster, showsKeyed, text]);

  return (
    <Router basepath="timeline">
      <MainTimeline path="/" albums={album} datesShowsObj={datesShowsObj} />
      <DateTimeline
        path=":date"
        datesShowsObj={datesShowsObj}
        showsKeyed={showsKeyed}
      />
      <ShowTimeline
        path=":date/:show"
        datesShowsObj={datesShowsObj}
        showsKeyed={showsKeyed}
      />
    </Router>
  );
};

export default TimelineWrapper;
