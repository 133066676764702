import React, { useCallback, useEffect, useState } from 'react';
import { PageProps, navigate, Link } from 'gatsby';
import styled, { css } from 'styled-components';

import Dropdown from '../shared/Dropdown';
import TimelineDateBlock from './TimelineDateBlock';
import DragScrollWrapper from '../shared/DragScrollWrapper';

import { MONTHS } from '../../constants';
import getDatePath from '../../utils/getDatePath';
import useRandomBgColor from '../../hooks/useRandomBgColor';

import border1Url from '../../assets/images/timeline_block_1.svg';
import { ReactComponent as Line } from '../../assets/images/timeline_line.svg';
import { ReactComponent as VerticalLine } from '../../assets/images/timeline_vline.svg';
import { ReactComponent as Arrow } from '../../assets/images/timeline_arrow.svg';

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  width: 100%;
  height: calc(100vh - 50px);
  margin-bottom: 50px;

  @media (max-width: ${props => props.theme.breakMedium}) {
    height: 100vh;
    margin: 0;
  }

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

const StyledBackButton = styled.div`
  display: inline-block;
  justify-self: center;
  margin-top: 2rem;
  text-align: center;
  ${props => props.theme.borderMask(border1Url)};

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin-top: calc(50px + 1rem);
  }
`;

const StyledBackLink = styled(Link)`
  padding: 0.5rem 0.8rem 0.3rem;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
  font-size: 0.625rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};
`;

const StyledDatePicker = styled.div`
  position: relative;
  text-align: center;
  padding: 2rem 0;

  > * {
    margin: 0 0.7rem;
    vertical-align: middle;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding: 1rem 0;
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    > * {
      margin: 0 0.5rem;
    }
  }
`;

type StyledArrowProps = {
  $isLeft?: boolean;
};

const StyledArrow = styled(Arrow)<StyledArrowProps>`
  width: 2rem;
  cursor: pointer;
  ${props =>
    props.$isLeft &&
    css`
      transform: scaleX(-1);
    `};

  @media (max-width: ${props => props.theme.breakSmall}) {
    width: 1.5rem;
  }
`;

const StyledTimelineContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: scroll;
`;

const StyledDragScrollWrapper = styled(DragScrollWrapper)`
  overflow: auto;
  height: 100%;
`;

const StyledTimeline = styled.div`
  display: inline-block;
  white-space: nowrap;
  height: 100%;

  @media (max-width: ${props => props.theme.breakMedium}) {
    padding-top: 1rem;
    width: 100%;
    white-space: initial;
  }
`;

type StyledLineProps = {
  $isMobile?: boolean;
};

const StyledLine = styled(Line)<StyledLineProps>`
  position: absolute;
  top: 0.7rem;
  width: 100%;
  stroke-width: 2px;
  z-index: -1;
  ${props =>
    props.$isMobile &&
    css`
      display: none;
    `};

  @media (max-width: ${props => props.theme.breakLarge}) {
    stroke-width: 3px;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    stroke-width: 4px;
    ${props =>
      props.$isMobile
        ? css`
            display: inline-block;
            top: initial;
            left: 0;
            bottom: -0.4rem;
            margin: 0;
            z-index: 2;
          `
        : css`
            display: none;
          `}
  }

  @media (max-width: ${props => props.theme.breakSmall}) {
    ${props =>
      props.$isMobile &&
      css`
        bottom: -0.3rem;
      `}
  }
`;

const StyledVerticalLine = styled(VerticalLine)`
  display: none;

  @media (max-width: ${props => props.theme.breakMedium}) {
    position: fixed;
    top: 10.2rem;
    left: 2.8rem;
    display: block;
    height: 100%;
    stroke-width: 2px;
    z-index: -1;
  }
`;

interface DateTimelineProps extends PageProps {
  datesShowsObj: Common.DatesShowsObj;
  showsKeyed: Common.ShowsKeyed;
}

const DateTimeline: React.FC<DateTimelineProps> = props => {
  const { datesShowsObj, showsKeyed, location } = props;

  useRandomBgColor();

  const [selectedDate, setSelectedDate] = useState<Common.DateObj>({
    year: '',
    month: '',
    date: '',
  });
  const [yearData, setYearData] = useState({});

  const years = Object.keys(datesShowsObj);

  useEffect(() => {
    // Get date from pathname
    const dateStringParts = location.pathname
      .split('/')
      .slice(-1)[0]
      .split('-');
    const pathDate: Common.DateObj = {
      year: dateStringParts[0],
      month: dateStringParts[1],
      date: dateStringParts[2],
    };

    setSelectedDate(pathDate);
    setYearData(datesShowsObj[pathDate.year]);
  }, [location.pathname, datesShowsObj]);

  const setYear = e => {
    navigate(
      `/timeline/${getDatePath({
        year: e.target.value,
        month: Object.keys(datesShowsObj[e.target.value])[0],
        date: 'all',
      })}`
    );
  };

  const setMonth = e => {
    navigate(
      `/timeline/${getDatePath({
        ...selectedDate,
        month: e.target.value,
        date: 'all',
      })}`
    );
  };

  const getMonthLabels = () => {
    const labelMap: Record<string, string> = {};

    Object.keys(yearData).forEach(month => {
      labelMap[month] = MONTHS[parseInt(month)];
    });

    return labelMap;
  };

  const handleArrowClick = useCallback(
    (isNext: boolean) => {
      const months = Object.keys(yearData);
      const monthIdx = months.indexOf(selectedDate.month);
      const yearIdx = years.indexOf(selectedDate.year);
      let newYear = selectedDate.year;
      let newMonth = selectedDate.month;

      if (isNext) {
        if (monthIdx == months.length - 1) {
          if (yearIdx == years.length - 1) {
            return;
          } else {
            newYear = years[yearIdx + 1];
            newMonth = Object.keys(datesShowsObj[newYear])[0];
          }
        } else {
          newMonth = months[monthIdx + 1];
        }
      } else {
        if (monthIdx == 0) {
          if (yearIdx == 0) {
            return;
          } else {
            newYear = years[yearIdx - 1];
            newMonth = Object.keys(datesShowsObj[newYear]).slice(-1)[0];
          }
        } else {
          newMonth = months[monthIdx - 1];
        }
      }

      navigate(
        `/timeline/${getDatePath({
          year: newYear,
          month: newMonth,
          date: 'all',
        })}`
      );
    },
    [datesShowsObj, selectedDate.month, selectedDate.year, yearData, years]
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.key == 'ArrowRight') {
        e.preventDefault();
        handleArrowClick(true);
      } else if (e.key == 'ArrowLeft') {
        e.preventDefault();
        handleArrowClick(false);
      }
    },
    [handleArrowClick]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown]);

  const renderContent = () => {
    if (selectedDate.year && selectedDate.month) {
      return Object.keys(
        datesShowsObj[selectedDate.year][selectedDate.month]
      ).map(date => (
        <TimelineDateBlock
          key={`${selectedDate.year}/${selectedDate.month}/${date}`}
          currDate={selectedDate}
          fullDate={{
            year: selectedDate.year,
            month: selectedDate.month,
            date: date,
          }}
          dateContents={
            datesShowsObj[selectedDate.year][selectedDate.month][date]
          }
          showsKeyed={showsKeyed}
        />
      ));
    }
  };

  return (
    <StyledContainer>
      <StyledBackButton>
        <StyledBackLink to="/timeline">Back to Albums</StyledBackLink>
      </StyledBackButton>
      <StyledDatePicker>
        <StyledArrow $isLeft onClick={() => handleArrowClick(false)} />
        <Dropdown
          borderUrl={border1Url}
          options={Object.keys(yearData)}
          labelMap={getMonthLabels()}
          selected={selectedDate.month}
          setSelected={setMonth}
        />
        <Dropdown
          borderUrl={border1Url}
          options={years}
          selected={selectedDate.year}
          setSelected={setYear}
        />
        <StyledArrow onClick={() => handleArrowClick(true)} />
        <StyledLine $isMobile />
      </StyledDatePicker>
      <StyledTimelineContainer>
        <StyledLine />
        <StyledVerticalLine />
        <StyledDragScrollWrapper>
          <StyledTimeline>{renderContent()}</StyledTimeline>
        </StyledDragScrollWrapper>
      </StyledTimelineContainer>
    </StyledContainer>
  );
};

export default DateTimeline;
