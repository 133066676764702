import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import Dropdown from '../shared/Dropdown';

import { MONTHS } from '../../constants';
import getDatePath from '../../utils/getDatePath';

import border1Url from '../../assets/images/timeline_block_1.svg';
import border2Url from '../../assets/images/timeline_block_2.svg';
import border3Url from '../../assets/images/timeline_block_3.svg';
import buttonBorderUrl from '../../assets/images/timeline_button.svg';
import { ReactComponent as Arrow } from '../../assets/images/timeline_arrow.svg';

const StyledContainer = styled.div`
  text-align: center;
  margin: 1rem 0;
`;

const StyledSection = styled.div`
  margin-bottom: 2rem;
`;

type StyledBorderProps = {
  borderUrl?: string;
};

const StyledBorder = styled.div<StyledBorderProps>`
  display: inline-block;
  ${props => props.theme.borderMask(props.borderUrl ?? border1Url)};
`;

const StyledLabelBorder = styled(StyledBorder)`
  margin-bottom: 1rem;
`;

const StyledLabel = styled.div`
  padding: 0.3rem;
  background: ${props => props.theme.white};
  font-size: 0.5625rem;
  text-align: center;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};
`;

const StyledDropdownWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 0.7rem;
`;

const StyledButtonsContainer = styled.div``;

type StyledButtonBorderProps = {
  disabled?: boolean;
};

const StyledButtonBorder = styled(StyledBorder)<StyledButtonBorderProps>`
  margin: 0 0.7rem 0.7rem;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.25;
    `};
`;

type StyledButtonProps = {
  active: boolean;
};

const StyledButton = styled.button<StyledButtonProps>`
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 0.9375rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};
  ${props =>
    props.active
      ? css`
          background: ${props => props.theme.black};
          color: ${props => props.theme.white};
        `
      : css`
          background: ${props => props.theme.white};
          color: ${props => props.theme.black};
        `};

  &:disabled {
    color: ${props => props.theme.black};
    cursor: default;
  }
`;

type StyledGoButtonProps = {
  $isDisabled: boolean;
};

const StyledGoButton = styled(Link)<StyledGoButtonProps>`
  padding: 1rem 1rem 0.8rem;
  background: ${props => props.theme.black};
  color: ${props => props.theme.white};
  font-size: 0.9375rem;
  text-align: center;
  white-space: nowrap;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(buttonBorderUrl)};

  ${props =>
    props.$isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.25;
    `};
`;

const StyledArrow = styled(Arrow)`
  width: 2.5rem;
  margin-left: 0.7rem;
  fill: ${props => props.theme.white};
`;

interface DatePickerProps {
  datesShowsObj: Common.DatesShowsObj;
}

const DatePicker: React.FC<DatePickerProps> = props => {
  const { datesShowsObj } = props;

  const [selectedDate, setSelectedDate] = useState<Common.DateObj>({
    year: '',
    month: '',
    date: '',
  });
  const [yearData, setYearData] = useState({});
  const [monthData, setMonthData] = useState({});

  const years = Object.keys(datesShowsObj);

  const handleYearClick = e => {
    setSelectedDate({
      year: e.target.value,
      month: '',
      date: '',
    });
    setYearData(datesShowsObj[e.target.value]);
  };

  const handleMonthClick = e => {
    setSelectedDate({
      ...selectedDate,
      month: e.target.value,
      date: 'all',
    });
    setMonthData(yearData[e.target.value]);
  };

  const handleDateClick = e => {
    setSelectedDate({
      ...selectedDate,
      date: e.target.value,
    });
  };

  return (
    <StyledContainer>
      <StyledSection>
        <StyledLabelBorder>
          <StyledLabel>Year</StyledLabel>
        </StyledLabelBorder>
        <StyledButtonsContainer>
          {years.map(year => (
            <StyledButtonBorder key={year}>
              <StyledButton
                value={year}
                active={selectedDate.year == year}
                onClick={handleYearClick}
              >
                {year}
              </StyledButton>
            </StyledButtonBorder>
          ))}
        </StyledButtonsContainer>
      </StyledSection>

      <StyledSection>
        <StyledLabelBorder>
          <StyledLabel>Month</StyledLabel>
        </StyledLabelBorder>
        <StyledButtonsContainer>
          {MONTHS.map((month, idx) => (
            <StyledButtonBorder key={idx} disabled={!(idx in yearData)}>
              <StyledButton
                value={idx}
                disabled={!(idx in yearData)}
                active={selectedDate.month == idx.toString()}
                onClick={handleMonthClick}
              >
                {month}
              </StyledButton>
            </StyledButtonBorder>
          ))}
        </StyledButtonsContainer>
      </StyledSection>

      <StyledSection>
        <StyledLabelBorder>
          <StyledLabel>Day</StyledLabel>
        </StyledLabelBorder>
        <StyledButtonsContainer>
          <StyledButtonBorder disabled={!selectedDate.month}>
            <StyledButton
              value="all"
              disabled={!selectedDate.month}
              active={selectedDate.date == 'all'}
              onClick={handleDateClick}
            >
              Entire month
            </StyledButton>
          </StyledButtonBorder>
          <StyledDropdownWrapper>
            <Dropdown
              borderUrl={border1Url}
              options={Object.keys(monthData)}
              selected={selectedDate.date == 'all' ? '-' : selectedDate.date}
              setSelected={handleDateClick}
              disabled={!selectedDate.month}
            />
          </StyledDropdownWrapper>
        </StyledButtonsContainer>
      </StyledSection>

      <StyledGoButton
        to={`/timeline/${getDatePath(selectedDate)}`}
        $isDisabled={
          !(selectedDate.year && selectedDate.month && selectedDate.date)
        }
      >
        Go
        <StyledArrow />
      </StyledGoButton>
    </StyledContainer>
  );
};

export default DatePicker;
