import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import WallObject from '../wall/WallObject';

import getOrdinals from '../../utils/getOrdinals';

import border1Url from '../../assets/images/timeline_block_1.svg';
import border2Url from '../../assets/images/photo_credit.svg';
import circleBorderUrl from '../../assets/images/gallery_arrow_border.svg';

const StyledDateBlock = styled.div`
  display: inline-block;
  vertical-align: top;
  padding: 0 2rem 1rem;

  @media (max-width: ${props => props.theme.breakMedium}) {
    display: block;
  }
`;

type StyledBorderProps = {
  borderUrl?: string;
};

const StyledBorder = styled.div<StyledBorderProps>`
  display: inline-block;
  ${props => props.theme.borderMask(props.borderUrl ?? border1Url)};
`;

const StyledDate = styled.div`
  padding: 0.5rem;
  background: ${props => props.theme.white};
  font-size: 0.9375rem;
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(border1Url)};

  sup {
    vertical-align: top;
    font-size: 0.6rem;
  }
`;

type StyledContentBlockProps = {
  $isSingle?: boolean;
  $isDisabled?: boolean;
};

const StyledContentBlock = styled(Link)<StyledContentBlockProps>`
  display: block;
  margin-top: 1rem;
  color: ${props => props.theme.black};

  ${props =>
    props.$isDisabled &&
    css`
      pointer-events: none;
    `};

  ${props =>
    props.$isSingle
      ? css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          ${StyledBorder} {
            margin-bottom: 0.5rem;
          }
        `
      : css`
          > * {
            vertical-align: top;
          }

          ${StyledBorder} {
            margin-right: 0.5rem;
          }

          ${StyledLabel} {
            width: 15rem;
            white-space: normal;
          }
        `};
`;

const StyledWallObject = styled(WallObject)`
  margin: 0;
`;

type StyledLabelProps = {
  borderUrl?: string;
  $isSmall?: boolean;
};

const StyledLabel = styled.div<StyledLabelProps>`
  padding: ${props => (props.$isSmall ? `0.5rem 0.5rem 0.3rem` : `0.5rem`)};
  background: ${props => props.theme.white};
  font-size: ${props => (props.$isSmall ? `0.625rem` : `0.75rem`)};
  ${props => props.theme.customTextStyle};
  ${props => props.theme.mask(props.borderUrl ?? border1Url)};
`;

const StyledPlus = styled(StyledLabel)`
  font-size: 1rem;
  padding: 0.5rem 0.3rem 0.3rem;
`;

const StyledMoreButton = styled.div`
  align-self: flex-end;
  text-align: right;

  > *:last-child {
    margin: 0 0.5rem;
  }

  ${StyledBorder} {
    margin-right: 0rem;
  }

  ${StyledLabel} {
    width: auto;
  }
`;

interface TimelineDateBlockProps {
  currDate: Common.DateObj;
  fullDate: Common.DateObj;
  dateContents: Common.DateContents;
  showsKeyed: Common.ShowsKeyed;
}

const TimelineDateBlock: React.FC<TimelineDateBlockProps> = props => {
  const { currDate, fullDate, dateContents, showsKeyed } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (
      currDate.year == fullDate.year &&
      currDate.month == fullDate.month &&
      currDate.date == fullDate.date
    ) {
      ref?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }, [currDate, fullDate]);

  const renderDateContents = () => {
    const shows = Object.entries(dateContents);
    return shows.map(([showSlug, showData]) => {
      const show = showsKeyed[showSlug].frontmatter;

      return (
        <StyledContentBlock
          key={showSlug}
          to={showSlug}
          $isDisabled={showData.length == 0}
          $isSingle={shows.length == 1}
        >
          <StyledBorder>
            <StyledLabel>{`${show.venue}, ${show.city}`}</StyledLabel>
          </StyledBorder>
          {showData.length > 0 && <StyledWallObject content={showData[0]} />}

          {showData.length > 1 && (
            <StyledMoreButton>
              <StyledBorder borderUrl={border2Url}>
                <StyledLabel borderUrl={border2Url} $isSmall>
                  See more
                </StyledLabel>
              </StyledBorder>
              <StyledBorder borderUrl={circleBorderUrl}>
                <StyledPlus borderUrl={circleBorderUrl}>+</StyledPlus>
              </StyledBorder>
            </StyledMoreButton>
          )}
        </StyledContentBlock>
      );
    });
  };

  return (
    <StyledDateBlock
      ref={ref}
      id={`${fullDate.year}/${fullDate.month}/${fullDate.date}`}
    >
      <>
        <StyledBorder>
          <StyledDate>
            {fullDate.date}
            <sup>{getOrdinals(fullDate.date)}</sup>
          </StyledDate>
        </StyledBorder>
      </>
      {renderDateContents()}
    </StyledDateBlock>
  );
};

export default TimelineDateBlock;
