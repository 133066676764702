import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import moment from "moment";

import DragScrollWrapper from "../shared/DragScrollWrapper";

import getRandomInt from "../../utils/getRandomInt";
import getDatePath from "../../utils/getDatePath";

import { ReactComponent as Line } from "../../assets/images/timeline_line.svg";
import albumBorderUrl from "../../assets/images/album_border.svg";
import border1Url from "../../assets/images/timeline_block_1.svg";
import border2Url from "../../assets/images/timeline_block_2.svg";
import border3Url from "../../assets/images/timeline_block_3.svg";
import { ReactComponent as Mug } from "../../assets/images/timeline_mug.svg";
import earlyCbBanner from "../../assets/images/early_cb.png";
import splitPeasBanner from "../../assets/images/a_sea_of_split_peas.png";
import sometimesBanner from "../../assets/images/sometimes_i_sit.png";
import lottaBanner from "../../assets/images/lotta_sea_lice.png";
import thingsTakeTimeBanner from "../../assets/images/things_take_time.png";
import thingsTakeTimeTexture from "../../assets/images/things_take_time-texture.jpg";
import endOfTheDayBanner from "../../assets/images/end_of_the_day.jpg";

const StyledContainer = styled.div`
  overflow: auto;
  height: 70vh;

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    height: 60vh;
  }
`;

const StyledTimelineWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const StyledDragScrollWrapper = styled(DragScrollWrapper)`
  height: 100%;
  padding-top: 1rem;
  overflow-x: auto;
`;

const StyledLine = styled(Line)`
  position: absolute;
  top: 22rem;
  width: 100vw;
  stroke-width: 2px;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakLarge}) {
    top: 20rem;
    stroke-width: 3px;
  }

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    top: 17rem;
    stroke-width: 4px;
  }
`;

const StyledTimeline = styled.div`
  display: inline-flex;
`;

const StyledAlbum = styled(Link)`
  display: flex;
  width: 33.33vw;
  align-items: center;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakLarge}) {
    width: 50vw;
  }

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    width: 100vw;
  }
`;

const StyledAlbumBorder = styled.div`
  width: 20rem;
  ${(props) => props.theme.borderMask(albumBorderUrl)};

  @media (max-width: ${(props) => props.theme.breakLarge}) {
    width: 18rem;
  }

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    width: 15rem;
  }
`;

const StyledAlbumCover = styled(Img)`
  overflow: hidden;
  ${(props) => props.theme.mask(albumBorderUrl)};
`;

type StyledTextProps = {
  maskUrl: string;
};

const StyledTextBorder = styled.div<StyledTextProps>`
  display: inline-block;
  max-width: 17rem;
  margin-top: 0.5rem;
  ${(props) => props.theme.borderMask(props.maskUrl)};
`;

const StyledText = css<StyledTextProps>`
  padding: 0.5rem;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black};
  text-align: center;
  ${(props) => props.theme.customTextStyle};
  ${(props) => props.theme.mask(props.maskUrl)};
`;

const StyledDate = styled.div`
  font-size: 0.9375rem;
  ${StyledText};
`;

const StyledTitle = styled.div`
  font-size: 0.75rem;
  ${StyledText};
`;

type StyledFillerProps = {
  $shouldHide?: boolean;
};

const StyledFiller = styled.div<StyledFillerProps>`
  width: 33.33vw;
  align-self: flex-end;

  @media (max-width: ${(props) => props.theme.breakLarge}) {
    ${(props) =>
      props.$shouldHide
        ? css`
            width: 0vw;
          `
        : css`
            width: 50vw;
          `};
  }

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    ${(props) =>
      props.$shouldHide
        ? css`
            width: 0vw;
          `
        : css`
            width: 100vw;
          `};
  }
`;

const StyledMug = styled(Mug)`
  display: block;
  width: 10rem;
  margin: 0 auto;
  stroke: black;
  stroke-width: 4px;
`;

const StyledBanner = styled.img`
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: -1;

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    top: 3rem;
  }
`;

const StyledBanner1 = styled(StyledBanner)`
  top: 4%;
  padding: 0 4vw;

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    top: calc(50px + 4%);
  }
`;

const StyledBanner2 = styled(StyledBanner)`
  top: -4%;
  padding: 0 10vw;

  @media (max-width: ${(props) => props.theme.breakMedium}) {
    top: calc(50px - 2%);
    padding: 0;
  }
`;

const background = {
  "Early CB": "#d9d9d9",
  "A Sea of Split Peas": "#f9e5cf",
  "Sometimes I Sit and Think, and Sometimes I Just Sit": "#04a852",
  "Lotta Sea Lice": "#3d3d3d",
  "Tell Me How You Really Feel": "#d84d49",
  "Things Take Time, Take Time": `url(${thingsTakeTimeTexture}) top/256px repeat`,
  "End of the Day (music from the film Anonymous Club)": "#2479fa",
};

const maskUrls = [border1Url, border2Url, border3Url];

const Album: React.FC = (props) => {
  const {
    content: { main_image, title, date, date_range },
  } = props;

  const momentDate = moment(date);
  const dateObj = {
    year: momentDate.year().toString(),
    month: momentDate.month().toString(),
    date: momentDate.date().toString(),
  };

  const [dateMaskUrl, setDateMaskUrl] = useState(border1Url);
  const [titleMaskUrl, setTitleMaskUrl] = useState(border1Url);

  useEffect(() => {
    setDateMaskUrl(maskUrls[getRandomInt(0, 2)]);
    setTitleMaskUrl(maskUrls[getRandomInt(0, 2)]);
  }, []);

  return (
    <StyledAlbum to={`/timeline/${getDatePath(dateObj)}`}>
      <StyledAlbumBorder>
        <StyledAlbumCover fluid={{ ...main_image?.childImageSharp.fluid }} />
      </StyledAlbumBorder>
      <StyledTextBorder maskUrl={dateMaskUrl}>
        <StyledDate maskUrl={dateMaskUrl}>{date_range}</StyledDate>
      </StyledTextBorder>
      <StyledTextBorder maskUrl={titleMaskUrl}>
        <StyledTitle maskUrl={titleMaskUrl}>{title}</StyledTitle>
      </StyledTextBorder>
    </StyledAlbum>
  );
};

interface AlbumTimelineProps {
  albums: {
    edges: {
      node: {
        childMdx: {
          id: string;
          frontmatter: Collections.Album;
          slug: string;
        };
      };
    }[];
  };
}

const AlbumTimeline: React.FC<AlbumTimelineProps> = (props) => {
  const { albums } = props;

  const [currAlbum, setCurrAlbum] = useState("Early CB");

  const timeline = useRef(null);

  useEffect(() => {
    document.body.style.background = background[currAlbum];
  }, [currAlbum]);

  albums.edges.sort(
    (a, b) =>
      new Date(a.node.childMdx.frontmatter.date) -
      new Date(b.node.childMdx.frontmatter.date)
  );

  const handleScroll = (e) => {
    const sectionWidth =
      timeline.current.clientWidth / (albums.edges.length + 2);

    const container = document.getElementById("container");

    const currentSection = Math.floor(
      (container?.scrollLeft + sectionWidth / 2) / sectionWidth
    );

    switch (currentSection) {
      case 0:
        setCurrAlbum("Early CB");
        break;
      case 1:
        setCurrAlbum("A Sea of Split Peas");
        break;
      case 2:
        setCurrAlbum("Sometimes I Sit and Think, and Sometimes I Just Sit");
        break;
      case 3:
        setCurrAlbum("Lotta Sea Lice");
        break;
      case 4:
        setCurrAlbum("Tell Me How You Really Feel");
        break;
      case 5:
        setCurrAlbum("Things Take Time, Take Time");
        break;
      case 6:
        setCurrAlbum("End of the Day (music from the film Anonymous Club)");
        break;
    }
  };

  const renderBanner = () => {
    if (currAlbum == "Early CB") {
      return <StyledBanner1 src={earlyCbBanner} />;
    } else if (currAlbum == "A Sea of Split Peas") {
      return <StyledBanner2 src={splitPeasBanner} />;
    } else if (
      currAlbum == "Sometimes I Sit and Think, and Sometimes I Just Sit"
    ) {
      return <StyledBanner src={sometimesBanner} />;
    } else if (currAlbum == "Lotta Sea Lice") {
      return <StyledBanner1 src={lottaBanner} />;
    } else if (currAlbum == "Things Take Time, Take Time") {
      return <StyledBanner src={thingsTakeTimeBanner} />;
    } else if (
      currAlbum == "End of the Day (music from the film Anonymous Club)"
    ) {
      return <StyledBanner src={endOfTheDayBanner} />;
    }
  };

  return (
    <StyledContainer onScroll={handleScroll}>
      {renderBanner()}
      <StyledTimelineWrapper>
        <StyledLine />
        <StyledDragScrollWrapper>
          <StyledTimeline ref={timeline}>
            <StyledFiller $shouldHide />
            {albums.edges.map((edge) => (
              <Album
                key={edge.node.childMdx.id}
                content={edge.node.childMdx.frontmatter}
              />
            ))}
            <StyledFiller>
              <StyledMug />
            </StyledFiller>
          </StyledTimeline>
        </StyledDragScrollWrapper>
      </StyledTimelineWrapper>
    </StyledContainer>
  );
};

export default AlbumTimeline;
