export default function getOrdinals(date: string): string {
  if (date == '11' || date == '12' || date == '13') {
    return 'th';
  }
  switch (date.slice(-1)) {
    case '1':
      return 'st';
    case '2':
      return 'nd';
    case '3':
      return 'rd';
    default:
      return 'th';
  }
}
